import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/content"

export const pageQuery = graphql`
  query VideoQuery($id: String!){
		markdownRemark(id: { eq: $id }) {
      id
			excerpt(pruneLength: 140)
      frontmatter {
        title
		subtitle
		abstract
		video_list{
			youtube_link
			youtube_code
			description
		}
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 600, maxHeight: 400, quality: 80, srcSetBreakpoints: [960, 1440]
					) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              src
            }
          }
        }
      }
    }
  }
`
const VideoPage = ({ data }) => {
	const { markdownRemark } = data // data.markdownRemark holds your post data
    const { frontmatter, excerpt } = markdownRemark
    const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""

	return (
		<Layout className="page">
			<SEO
				title={frontmatter.title}
				description={excerpt}
			/>
			<div className="wrapper">
				{Image ? (
          		  <Img 
          		    fluid={Image} 
          		    alt={frontmatter.title + ' - Featured image'}
          		    className="top-page-image"
					fadeIn={false}
          		  />
          		) : ""}
				<h1>{frontmatter.title}</h1>
				<Content source={frontmatter.abstract} />
				{frontmatter.subtitle && <h3>{frontmatter.subtitle}</h3>}
				{frontmatter.video_list.map((list, index) => (
				<div className="video-card grids col-1 sm-2">
					<div>
						<article dangerouslySetInnerHTML={{ __html: list.youtube_code}} />
					</div>
					<div>
						<Content source={list.description} />
						<a href={list.youtube_link}>{list.youtube_link}</a>
					</div>
				</div>
				))}
			</div>
		</Layout>
	)
}

export default VideoPage
